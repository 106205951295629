import { Suspense, lazy, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Loader from './components/Common/Loader'
import VerifyPaymentStatus from './components/Common/VerifyPaymentStatus'
import UserMasterNew from './components/Admin/Pages/NewUserMaster'
import ViewAllDestination from './components/pages/ViewAllDestination'
import Plans from './components/pages/Plans'
import CheckAuthenticationForPayment from './components/pages/checkAuthenticationForPayment'
import Privacypolicy from './components/pages/Privacypolicy'
import RefundPolicy from './components/pages/RefundPolicy'
import DeviceMaster from './components/Admin/Pages/DeviceMaster'
import PromotionMaster from './components/Admin/Pages/PromotionMaster'
import Addonplans from './components/pages/Addonplans'
import ForgotPassword from './components/pages/ForgotPassword'
import ResetPasswordField from './components/pages/ResetPasswordField'
import Import from './components/Admin/Pages/Import'
import UploadPlanImage from './components/Admin/Pages/UploadPlanImage'
import PaymentDiscrepancyLogReport from './components/Admin/Pages/PaymentDiscrepancyLogReport'

const SimflyPartnerPlateform = lazy(() => import('./components/pages/Partnerwithus/SimflyPartnerPlateform'))
const Destination = lazy(() => import('../src/components/pages/Destination'))
const Home = lazy(() => import('../src/components/pages/Home'))
const AuthLayout = lazy(() => import('./components/Admin/Layout/AuthLayout'))
const AdminLogin = lazy(() => import('./components/Admin/Login/AdminLogin'))
const ChangePassword = lazy(() => import('./components/Admin/Pages/ChangePassword'))
const GroupMaster = lazy(() => import('./components/Admin/Pages/GroupMaster'))
const GroupMenuAccess = lazy(() => import('./components/Admin/Pages/GroupMenuAccess'))
const ResetPassword = lazy(() => import('./components/Admin/Pages/ResetPassword'))
const UserLogReport = lazy(() => import('./components/Admin/Pages/UserLogReport'))
const UserMaster = lazy(() => import('./components/Admin/Pages/UserMaster'))
const UserMenuAccess = lazy(() => import('./components/Admin/Pages/UserMenuAccess'))
const Checkout = lazy(() => import('./components/Common/Checkout'))
const Pagenotfound = lazy(() => import('./components/Common/Pagenotfound'))
const AdminLayout = lazy(() => import('./components/Layout/AdminLayout'))
const MainLayout = lazy(() => import('./components/Layout/MainLayout'))
const Profile = lazy(() => import('./components/pages/Profile/Profile'))
const Customer = lazy(() => import('./components/Admin/Pages/Customer'))
const OrderReport = lazy(() => import('./components/Admin/Pages/OrderHistory'))
const PlanMaster = lazy(() => import('./components/Admin/Pages/PlanMaster'))
const PlanImageMaster = lazy(() => import('./components/Admin/Pages/PlanImageMaster'))
const GeneralMaster = lazy(() => import('./components/Admin/Pages/GeneralMaster'))
const CustomerReport = lazy(() => import('./components/Admin/Pages/CustomerReport'))
const FAQs = lazy(() => import('./components/pages/FAQs'))
const Contactus = lazy(() => import('./components/pages/Contactus'))
const Aboutus = lazy(() => import('./components/pages/Aboutus'))
const Login = lazy(() => import('./components/Login/login'))



export const ScrollToTop = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return null;
}

const App = () => {
    const authEmail = localStorage.getItem('emailID')
    const authLoginToken = localStorage.getItem('loginToken')
    const authLoginUserCode = localStorage.getItem('loginUserCode')

    return (
        <>
            <ScrollToTop />
            {/*  <Loader/> */}
            <ToastContainer position="bottom-center" newestOnTop={true} />
            <Suspense fallback={<Loader />}>
                <Routes>
                    <Route path='/' element={<MainLayout />}>
                        <Route index element={<Home />} />
                        <Route path='*' element={<Pagenotfound />} />
                        <Route path='/login' element={
                            authEmail !== null && authLoginToken !== null && authLoginUserCode !== null ?
                                <Home /> : <Login />
                        } />
                      {/*  <Route path='/destination' element={<Destination />} />*/}
                        <Route path='/Plans/:Id' element={<Plans />} />
                        <Route path='/destinations' element={<ViewAllDestination />} />
                        <Route path='/profile' element={<Profile />} />
                        <Route path='/checkout' element={<Checkout />} />
                        <Route path='/CreateOrder' element={<VerifyPaymentStatus />} />
                        <Route path='/simfly_new-partner-platform' element={<SimflyPartnerPlateform />} />
                        <Route path='/aboutus' element={<Aboutus />} />
                        <Route path='/contactus' element={<Contactus />} />
                        <Route path='/authentication' element={<CheckAuthenticationForPayment />} />
                        <Route path='/FAQs' element={<FAQs />} />
                        <Route path='/privacy-cookie-policy' element={<Privacypolicy />} />
                        <Route path='/refund-policy' element={<RefundPolicy />} />
                        <Route path='/add-on-plans' element={<Addonplans />} />
                        <Route path='/forgot-password' element={<ForgotPassword />} />
                        <Route path='/resetPasswordSlide' element={<ResetPasswordField />} />
                    </Route>
                    <Route path='/admin/*' element={<AdminLayout />}>
                        <Route index element={<AdminLogin />} />
                        <Route path="" element={<AuthLayout />}>
                            <Route path='*' element={<Pagenotfound />} />
                            <Route path="UserMaster" element={<UserMaster />} />
                            <Route path="ResetPassword" element={<ResetPassword />} />
                            <Route path="ChangePassword" element={<ChangePassword />} />
                            <Route path="GroupMenuAccess" element={<GroupMenuAccess />} />
                            <Route path="UserMenuAccess" element={<UserMenuAccess />} />
                            <Route path="GroupMaster" element={<GroupMaster />} />
                            <Route path="UserLogReport" element={<UserLogReport />} />
                            <Route path="CustomerMaster" element={<Customer />} />
                            <Route path="OrderReport" element={<OrderReport />} />
                            <Route path="PlanMaster" element={<PlanMaster />} />
                            <Route path="DeviceMaster" element={<DeviceMaster />} />
                            <Route path="PlanImageMaster" element={<PlanImageMaster />} />
                            <Route path="CustomerReport" element={<CustomerReport />} />
                            <Route path="newUserMAster" element={<UserMasterNew />} />
                            <Route path="GeneralMaster" element={<GeneralMaster />} />
                            <Route path="PromotionMaster" element={<PromotionMaster />} />
                            <Route path="ImportPlanDetails" element={<Import />} />
                            <Route path="UploadPlanImage" element={<UploadPlanImage />} />
                            <Route path="PaymentDiscrepancyLog" element={<PaymentDiscrepancyLogReport />} />
                        </Route>
                    </Route>
                </Routes>
            </Suspense>
        </>
    )
}

export default App;